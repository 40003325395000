import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <>
            <div className='px-[80px]'>
                <nav className='flex justify-between items-center text-[18px] text-[var(--text-color)] border-b-[1px]'>
                    <div>
                        <NavLink to={"/"}>
                            <img src="/assets/images/landing/quidx_logo.png" alt="" />
                        </NavLink>
                    </div>
                    <ul className='flex'>
                        <li className='px-[27px] py-[26px]'>
                            <NavLink className={({ isActive }) => isActive ? 'font-semibold border-b-2 border-[var(--text-color)]' : ''} to={"/landing"}>
                                Home
                            </NavLink>
                        </li>
                        <li className='px-[27px] py-[26px]'>
                            <NavLink to={"/"}>
                                Chat
                            </NavLink>
                        </li>
                        <li className='px-[27px] py-[26px]'>
                            <NavLink to={"/"}>
                                Pricing
                            </NavLink>
                        </li>
                        <li className='px-[27px] py-[26px]'>
                            <NavLink to={"/"}>
                                FAQs
                            </NavLink>
                        </li>
                    </ul>
                    <div>
                        <button className='px-[16px] py-[8px]'>Login</button>
                        <button className='bg-[var(--primary-color)] text-white px-[16px] py-[8px] rounded-[4px]'>Sign up</button>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar