import React, { useEffect, useState, createContext, useRef } from "react";
import { HStack, Text, VStack, Spinner, useToast } from "@chakra-ui/react";

import ChatLayout from "../components/ChatLayout";
import ChatCard from "../components/ChatCard";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MdMessage } from "react-icons/md";

export const ChatContext = createContext();

const Chat = () => {
	const navigate = useNavigate();
	const toast = useToast();
	const params = useParams();
	const ref = useRef();
	const [chats, setChats] = useState([]);
	const [aiLoading, setAiLoading] = useState(false);
	const [chatId, setChatId] = useState(params.chatId ? params.chatId : "");
	const [toggleHistory, setToggleHistory] = useState(false);

	const fetchChats = async () => {
		console.log(params);
		try {
			// Make the API request
			const response = await axios.get(
				`${process.env.REACT_APP_CHAT_BASE_URL
				}conversations/${localStorage.getItem("userId")}`
			);

			// Log the response
			console.log(response);

			// Parse and set the chats
			// const parsedMessage = JSON.parse(response.data[0].message);
			// console.log(parsedMessage);
			// setChats(parsedMessage);
		} catch (error) {
			// Handle errors and show a warning toast
			console.error("Error fetching chats:", error);
			toast({
				title: "An error occurred while fetching chats",
				status: "warning",
			});
		}
	};

	const fetchSingleChat = async (chatId) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_CHAT_BASE_URL
				}conversation/${localStorage.getItem("userId")}/${chatId}`
			);
			console.log(response);
			setChats(JSON.parse(response.data[0].message))
		} catch (error) {
			console.error("Error fetching chats:", error);
			toast({
				title: "An error occurred while fetching chats",
				status: "warning",
			});
		}
	}

	useEffect(() => {
		console.log(params.chatId, "Chat id in the chat page");
		if (params.chatId) {
			fetchSingleChat(chatId);
		}else{
			fetchChats()
		}
	}, [params]);

	useEffect(() => {
		if (localStorage.getItem("quidsToken") == null) {
			navigate("/login");
			return;
		}
		if (chats.length) {
			ref.current?.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	}, [chats.length, aiLoading]);
	return (
		<ChatContext.Provider
			value={{
				chats,
				setChats,
				aiLoading,
				setAiLoading,
				setChatId,
				chatId,
				toggleHistory,
				setToggleHistory,
			}}
		>
			<ChatLayout>
				<VStack width={"full"} height={"full"} overflowY={"scroll"}>
					{!params.chatId && (
						<HStack display={["flex", "flex", "flex"]}>
							<Text>Start New Chat</Text>
							<MdMessage />
						</HStack>
					)}

					{chats ? (
						chats.length > 0 ? (
							chats.map((chat, id) => {
								const role = id % 2;
								return (
									<>
										<ChatCard
											key={`${id}a`}
											role={"user"}
											content={chat["user"]}
										/>
										<ChatCard
											key={`${id}b`}
											role={"quidx-ai"}
											content={chat["quidx-ai"]}
										/>
									</>
								);
							})
						) : (
							""
						)
					) : (
						<Text>No chat session</Text>
					)}
					{aiLoading ? (
						<VStack width={"full"} alignItems={"center"}>
							<Spinner />
							<Text>QuidxAI is thinking...</Text>
						</VStack>
					) : (
						""
					)}
					<div ref={ref} />
				</VStack>
			</ChatLayout>
		</ChatContext.Provider>
	);
};

export default Chat;
