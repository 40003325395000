import React, { useEffect, useRef, useState } from 'react'
import { Cursor, useTypewriter } from 'react-simple-typewriter'
import Navbar from '../components/Navbar'
import { TbTools } from "react-icons/tb";
import { MdArrowBack, MdArrowForward, MdOutlineLibraryBooks } from "react-icons/md";
import { TbBrandWechat } from "react-icons/tb";
import { BsPeople } from "react-icons/bs";
import { LiaTagsSolid } from "react-icons/lia";
import { FaCalculator, FaTools } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { motion, useAnimation, useInView, useScroll, useTransform } from 'framer-motion';
import "../App.css";
import Footer from '../components/Footer';



// const useInView = (options) => {
//     const ref = useRef(null);
//     const [inView, setInView] = useState(false);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             (entries) => {
//                 const [entry] = entries;
//                 setInView(entry.isIntersecting);
//             },
//             { threshold: 0.1, ...options }
//         );

//         if (ref.current) {
//             observer.observe(ref.current);
//         }

//         return () => {
//             if (ref.current) {
//                 observer.unobserve(ref.current);
//             }
//         };
//     }, [ref, options]);

//     return [ref, inView];
// };


const Landing = () => {
    const [isIntersecting, setIsIntersecting] = useState(false)
    const containerRef = useRef(null);
    const containerRef2 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { rootMargin: "-300px" }
        );
        observer.observe(containerRef.current);

        return () => observer.disconnect();
    }, [isIntersecting])

    useEffect(() => {
        if (isIntersecting) {
            containerRef.current.querySelectorAll(".ss").forEach((el) => {
                el.classList.add("slide-in");
            });
        } else {
            containerRef.current.querySelectorAll(".ss").forEach((el) => {
                el.classList.remove("slide-in");
            });
        }
    }, [isIntersecting])
    useEffect(() => {
        if (isIntersecting) {
            containerRef2.current.querySelectorAll(".swipe2").forEach((el) => {
                el.classList.add("slide-in-bottom");
            });
        } else {
            containerRef2.current.querySelectorAll(".swipe2").forEach((el) => {
                el.classList.remove("slide-in-bottom");
            });
        }
    }, [isIntersecting])

    const [typeWriterEffect] = useTypewriter({
        words: [
            "Revolutionizing Forex and Crypto Trading!"
        ],
        loop: true,
        typeSpeed: 20,
        deleteSpeed: 80,
        delaySpeed: 2000,
    })

    // const [slideInRef1, inView1] = useInView();
    // const [slideInRef2, inView2] = useInView();

    return (
        <>
            <div className='bg-[var(--bg-color)]'>
                <div className="landing_bg ">
                    <Navbar />
                    <div className='text-center px-[300px]'>
                        <h1 className='text-[54px] font-[700] text-[var(--text-color)] px-[100px] w-full h-[170px] mt-[70px]'>{typeWriterEffect}<Cursor />{" "}</h1>
                        <p className='text-[18px] font-[500] py-[50px]'>Discover a new era of simplicity and profitability in the world of forex and cryptocurrency trading with Quidstation.</p>
                        <button className='bg-[var(--primary-color)] text-white px-[22px] py-[10px] rounded-[4px]'>Get Started</button>
                    </div>
                </div>
                <div ref={containerRef} className='flex items-center px-[80px] py-[180px] overflow-hidden'>
                    <div className='w-3/6 pr-[100px] translate-x-[-150%] duration-[1s] ss'>
                        <h1 className='text-[28px] font-[600] text-[var(--secondary-color)] mb-5'>About Quids.Ai</h1>
                        <p className='text-[var(--support-text-color)] mb-12 font-[500]'>We understand that time is of the essence in the fast-paced trading environment. That's why we've developed a user-friendly platform that allows you to execute trades effortlessly and efficiently.</p>
                        <button className='bg-[var(--primary-color)] text-white px-[22px] py-[10px] rounded-[4px]'>Get Started</button>
                    </div>
                    <div className='w-3/6 translate-x-[150%] duration-[1s] ss'>
                        <div className='relative section2bg w-5/6 h-[261px] m-auto'>
                            <img className='w-3/6 h-[200px] absolute right-0 top-[-150px]' src="../../assets/about/img2.png" alt="" />
                            <img className='w-3/6 h-[200px] absolute left-0 bottom-[-150px]' src="../../assets/about/img3.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className='bg-[var(--secondary-color)] flex justify-center gap-10 text-white py-20'>
                    <div className='flex items-center'>
                        <MdOutlineLibraryBooks className='mr-5 bg-[var(--bg-color)] p-2 rounded-md text-[var(--primary-color)] text-[60px]' />
                        <p>100k+ <br /> Resources</p>
                    </div>
                    <div className='flex items-center'>
                        <TbTools className='mr-5 bg-[var(--bg-color)] p-2 rounded-md text-[var(--primary-color)] text-[60px]' />
                        <p>2k+ <br /> Trading Tools</p>
                    </div>
                    <div className='flex items-center'>
                        <LiaTagsSolid className='mr-5 bg-[var(--bg-color)] p-2 rounded-md text-[var(--primary-color)] text-[60px]' />
                        <p>100k+ <br /> Options</p>
                    </div>
                    <div className='flex items-center'>
                        <BsPeople className='mr-5 bg-[var(--bg-color)] p-2 rounded-md text-[var(--primary-color)] text-[60px]' />
                        <p>10k+ <br /> Users</p>
                    </div>
                </div>

                <main className='flex gap-2' ref={containerRef2}>
                    <div className='swipe2 w-3/12 h-[200px] bg-green-200'></div>
                    <div className='swipe2 w-3/12 h-[200px] bg-green-200'></div>
                    <div className='swipe2 w-3/12 h-[200px] bg-green-200'></div>
                </main>

                <div className="px-[80px]">
                    <h1 className='text-center text-[28px] font-[600] my-[55px]'>We Provide</h1>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {[...Array(6)].map((_, index) => (
                            <SwiperSlide className='swipe2' key={index}>
                                <motion.div
                                    className='swipe-up duration-[1s] translate-y-[150%]'
                                    initial={{ opacity: 0, y: 30 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="slide-in-bottom rounded-[36px] py-10 px-[40px] bg-[var(--card-bg)] text-center">
                                        <TbBrandWechat className='text-[74px] m-auto bg-[var(--secondary-color)] p-3 rounded-md text-white' />
                                        <h2 className='slide-in-top text-[20px] font-[600] my-7'>ChatX</h2>
                                        <p className='slide-in-top mb-14 text-[15px]'>Our platform provides comprehensive charts, indicators, and market analysis, empowering you to make informed trading decisions based on accurate information.</p>
                                        <button className='bg-[var(--primary-color)] text-white px-[22px] py-[10px] rounded-[4px]'>Learn More</button>
                                    </div>
                                </motion.div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className='flex bg-[#ECF8ED] py-10 px-[80px] my-20 w-[80vw] box-border items-center'>
                    <div className='min-w-[35vw]'>
                        <div>
                            <h1 className='text-[28px] font-[600] mb-10'>Popular Products</h1>
                            <p className='text-[var(--support-text-color)]'>We understand that time is of the essence in the fast-paced trading environment. That's why we've developed a user-friendly platform that allows you to execute trades effortlessly and efficiently.</p>
                        </div>
                        <div className='mt-36'>
                            <button>
                                <MdArrowBack />
                            </button>
                            <button>
                                <MdArrowForward />
                            </button>
                        </div>
                    </div>

                    <div className='flex min-w-[50vw]'>
                        <div className='w-3/6'>
                            <div className='bg-[var(--primary-color)] relative left-[100px] py-10 px-5 box-border rounded-[36px] text-center text-[var(--bg-color)]'>
                                <FaCalculator className='m-auto text-[var(--secondary-color)] bg-[var(--bg-color)] p-3 rounded-md text-[60px]' />
                                <h2 className='text-[20px] font-[600] my-8'>Risk Calculator</h2>
                                <p>Use proper risk management by calculating your risk with just a few clicks Use proper risk management by calculating your risk with just a few clicks</p>
                                <button className='text-[var(--secondary-color)] bg-[var(--bg-color)] px-[20px] py-[10px] mt-14 rounded-md'>Learn More</button>
                            </div>
                        </div>
                        <div className='flex items-center w-3/6'>
                            <div className='bg-[var(--card-bg)] py-10 px-5 rounded-[36px] text-center'>
                                <FaCalculator className='m-auto text-[var(--bg-color)] bg-[var(--secondary-color)] p-3 rounded-md text-[60px]' />
                                <h2 className='text-[20px] text-[var(--secondary-color)] font-[600] my-8'>Risk Calculator</h2>
                                <p className='text-[var(--support-text-color)]'>Our platform provides comprehensive charts, indicators, and market analysis</p>
                                <button className='bg-[var(--primary-color)] text-[var(--bg-color)] px-[20px] py-[10px] mt-8'>Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='px-[80px] text-center'>
                    <div className="px-[270px]">
                        <h1 className='text-[28px] font-[600]'>What clients say about Quidx</h1>
                        <p className='font-[500] py-10'>Success stories from our satisfied users, showcasing the positive impact Quidstation has made on their trading experience.</p>
                    </div>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {[...Array(4)].map((_, index) => (
                            <SwiperSlide key={index}>
                                <motion.div
                                    initial={{ opacity: 0, y: 30 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="slide-in-bottom rounded-[36px] py-10 px-[40px] bg-[var(--card-bg)] text-center">
                                        <img className='m-auto' src="../../assets/images/avatar.png" alt="" />
                                        <h2 className='slide-in-top text-[20px] font-[600] text-[var(--secondary-color)]'>Adegoke Wasiu</h2>
                                        <p>CEO of Estrades</p>
                                        <img className='m-auto' src="../../assets/images/5stars.png" alt="" />
                                        <div className='flex'>
                                            <p className='text-[72px] text-[var(--secondary-color)]'>“</p>
                                            <p className='slide-in-top my-7 text-[15px]'>Exceptional customer support at Quidstation. Responsive team resolves inquiries and technical issues promptly.</p>
                                            <p className='text-[72px] self-end text-[var(--secondary-color)] rotate-180'>“</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className='px-[80px] my-10 text-[var(--bg-color)]'>
                    <div className='bg-[var(--secondary-color)] py-10 text-center rounded-[24px]'>
                        <h1 className='text-[30px] font-[600]'>Stay Informed</h1>
                        <p className='mb-10'>Subscribe to the newsletter to hear about Quidx AI updates and events</p>
                        <div className='w-fit m-auto border-[1px] rounded-[8px]'>
                            <button className='bg-[var(--bg-color)] font-[500] px-10 py-6 rounded-l-[8px] text-[var(--secondary-color)]'>Join Waitlist</button>
                            <input className='bg-transparent py-6 w-[500px] pl-4' type="text" placeholder='Enter Email' />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Landing