// import React from "react";
import ThemeLayout from "../components/ThemeLayout";
// import { HStack, Text, VStack, Input, Button } from "@chakra-ui/react";
// import PricingCard from "../components/PricingCard";
// import SectionContainer from "../components/SectionContainer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

// const Plans = () => {
//   return (
//     <ThemeLayout>
//       <Header showLogo={true} />

//       <SectionContainer>
//         {" "}
//         <VStack gap={"20px"}>
//           <Text
//             fontSize={["18px", "18px", "24px"]}
//             fontWeight={500}
//             marginTop={"10px"}
//           >
//             Pricing
//           </Text>
//           <Text fontSize={["28px", "28px", "36px"]} fontWeight={600}>
//             Our Packages
//           </Text>
//           <Text
//             textAlign={"center"}
//             fontSize={["18px", "18px", "24px"]}
//             fontWeight={500}
//           >
//             Choose the Package That Fits Your Trading Goals
//           </Text>
//           <HStack
//             width={"full"}
//             flexDir={["column", "column", "column", "row"]}
//             gap={["40px", "40px", "50px"]}
//           >
//             <PricingCard
//               title={"Basic Plan"}
//               price={40}
//               features={[
//                 "Access to our user-friendly trading platform.",
//                 "Real-time market data and basic charting tools.",
//                 "Limited access to educational resources.",
//               ]}
//             />
//             <PricingCard
//               title={"Starter Plan"}
//               role="main"
//               price={150}
//               features={[
//                 "Full access to our advanced trading platform.",
//                 "Comprehensive market analysis tools and indicators.",
//                 "Competitive pricing with additional features ",
//               ]}
//             />
//             <PricingCard
//               title={"Premium Plan"}
//               price={300}
//               features={[
//                 "Exclusive access to premium features ",
//                 "Customizable charts and in-depth technical analysis.",
//                 "VIP educational resources, including advanced trading strategies.",
//               ]}
//             />
//           </HStack>
//         </VStack>
//       </SectionContainer>
//       <SectionContainer>
//         <HStack
//           mt={"30px"}
//           display={"flex"}
//           flexDir={["column", "row"]}
//           width={"full"}
//           gap={[5, 5, 10]}
//           alignItems={"center"}
//           bg={"#1B4B1D"}
//           boxShadow={"0px 1px 4px rgba(255, 255, 255, 0.04)"}
//           borderRadius={"24px"}
//           p={["0px", "96px 56px"]}
//           justifyContent={["center", "space-between"]}
//           border={"10px solid #F2F2F2"}
//           py={"70px"}
//           paddingRight={["0px", "160px"]}
//         >
//           <Text
//             color={"#F8FCF8"}
//             fontWeight={"500"}
//             fontSize={["14px", "14px", "30px"]}
//             textAlign={["center", "left"]}
//           >
//             Ready to Explore Quidx? Claim <br />
//             your free account{" "}
//           </Text>

//           <Button
//             bg={"#DAF1DB"}
//             color={"black"}
//             height={"60px"}
//             borderRadius={"4px"}
//             fontWeight={"500"}
//             paddingX={"70px"}
//           >
//             Get Started
//           </Button>
//         </HStack>
//       </SectionContainer>
//     </ThemeLayout>
//   );
// };

// export default Plans;
import React from 'react'

const Plans = () => {
const plans = [
  {
    text1: "Basic Plan",
    credit: 50,
    amount: 100,
    text2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },
  {
    text1: "Basic Plan",
    credit: 50,
    amount: 100,
    text2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },
  {
    text1: "Basic Plan",
    credit: 50,
    amount: 100,
    text2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },
  {
    text1: "Basic Plan",
    credit: 50,
    amount: 100,
    text2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },
]
  return (
    <>
      <ThemeLayout>
      {/* <Header showLogo={true} /> */}
        <div className="text-center bg-[var(--primary-color)]">
      {/* <Header showLogo={true} /> */}
      <Navbar />

          <div className="text-white">
          <h1 className="text-[36px] font-[600]">Our Packages</h1>
          <p className="text-[24px]">Choose the Package That Fits Your Trading Goals</p>
          <div className="flex gap-2 px-[80px]">
          {
            plans.map((el)=>(
              <div className="bg-[var(--card-bg)] w-3/12">
                <p>{el.text1}</p>
                <h1>{el.amount}c <small>/${el.credit}</small> </h1>
                <p>{el.text2}</p>
                <button className="bg-[var(--primary-color)] text-white">Buy Now</button>
              </div>
            ))
          }
          </div>
          </div>
        </div>
      </ThemeLayout>
    </>
  )
}

export default Plans