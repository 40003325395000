import { Image, VStack, Box, HStack, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import SectionContainer from "./SectionContainer";
import { BsArrowRight, BsChatLeft, BsPerson, BsPlus } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import { FaEllipsisH } from "react-icons/fa";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChatContext } from "../pages/Chat";
import { shortifyConvoTitle } from "../utils/utils";

const ChatSidebar = (props) => {
	const { chats, setChats, setAiLoading, chatId, setChatId } =
		useContext(ChatContext);
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const toast = useToast();
	const [loading, setLoading] = useState(true);

	const [chatHistory, setChatHistory] = useState([]);

	const fetchConversations = async () => {
		console.log(localStorage.getItem("userId"), "User_id on side nav");
		await axios
			.get(
				`${
					process.env.REACT_APP_CHAT_BASE_URL
				}conversations/${localStorage.getItem("userId")}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
					},
				}
			)
			.then((response) => {
				console.log(response);
				setChatHistory(response.data);
			})
			.catch((error) => {
				const statusCode = error.response.data.statusCode;

				if (statusCode === 401) {
					toast({
						title: "Unauthorized access. Please log in to continue.",
						status: "warning",
					});
					navigate("/signin");
				} else {
					toast({ title: "An error occurred", status: "warning" });
				}
			});
	};

	const appendPath = (path) => {
		const currentPath = location.pathname;
		let newPath;
		if (chatId) {
			const paths = currentPath.split("/");

			newPath = `${paths[0]}/${paths[1]}/${path}`;
			navigate(newPath);
		} else {
			newPath = `${currentPath}/${path}`;
			navigate(newPath);
		}
	};

	useEffect(() => {
		fetchConversations();
	}, []);
	return (
		<VStack width="full" height={"full"} position={"relative"} bg={"#1B4B1D"}>
			<SectionContainer>
				<VStack width={"full"} gap={5}>
					<Image src="/assets/images/quidsailogo.png" width={"66px"} />
					<VStack width={"full"} height={"400px"} overflowY={"scroll"} gap={5}>
						<HStack
							width={"full"}
							color={"#FCFCFC"}
							alignItems={"center"}
							padding={2}
							border={"0.5px solid #FCFCFC"}
							borderRadius={"4px"}
							cursor={"pointer"}
							onClick={() => {
								setChats([]);
								setChatId("");
								navigate("/chat");
							}}
						>
							<BsPlus fontSize={"30px"} cursor={"pointer"} />
							<Text fontSize={"14px"}>New Chat</Text>
						</HStack>
						{chatHistory.length > 0 ? (
							chatHistory.map((chat) => {
								return (
									<HStack
										width={"full"}
										color={"#FCFCFC"}
										alignItems={"center"}
										cursor={"pointer"}
										key={chat.id}
										onClick={() => {
											setChatId(chat.id);
											console.log(chat.id, "Not sure if its the chat id or conversation id");
											navigate(`/chat/${chat.id}`);
										}}
									>
										<BsChatLeft fontSize={"24px"} />
										<Text fontSize={"16px"} color={"#FCFCFC"}>
											{shortifyConvoTitle(chat.message)}
										</Text>
									</HStack>
								);
							})
						) : (
							<Text fontSize={"16px"} color={"#FCFCFC"}>
								No chat history
							</Text>
						)}
					</VStack>
				</VStack>
			</SectionContainer>
			<VStack width={"full"} bg={"#ECF8ED"} position={"absolute"} bottom={0}>
				<SectionContainer>
					<VStack width={"full"} gap={2}>
						<HStack width={"full"} justifyContent={"space-between"}>
							<HStack gap={2}>
								<MdSettings fontSize={"28px"} />
								<Text>Upgrade Plan</Text>
							</HStack>
							<Box
								position={"relative"}
								transition={" 0.3s  "}
								_hover={{ position: "absolute", right: 5 }}
								cursor={"pointer"}
							>
								<BsArrowRight fontSize={"24px"} />
							</Box>
						</HStack>
						<HStack width={"full"} justifyContent={"space-between"}>
							<HStack gap={2}>
								<BsPerson fontSize={"28px"} />

								<Text>Account Name</Text>
							</HStack>
							<Box
								position={"relative"}
								transition={"position 5s  "}
								_hover={{ position: "absolute", right: 5 }}
								cursor={"pointer"}
							>
								<FaEllipsisH fontSize={"24px"} letterSpacing={"200px"} />
							</Box>
						</HStack>
					</VStack>
				</SectionContainer>
			</VStack>
		</VStack>
	);
};

export default ChatSidebar;
