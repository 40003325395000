import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages";
import Chat from "../pages/Chat";
import Plans from "../pages/Plans";
import FAQ from "../pages/FAQ";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Subscriptions from "../pages/Subscriptions";
import Dashboard from "../pages/Dashboard";
import Calculator from "../pages/Calculator";
import Verify from "../pages/Verify";
import Landing from "../pages/Landing";
import Hey from "../pages/Hey";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/landing" element={<Landing />} />
        <Route exact path="/hey" element={<Hey />} />
        <Route exact path="/chat" element={<Chat />} />
        <Route exact path="/chat/:chatId" element={<Chat />} />

        <Route exact path="/pricing" element={<Plans />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/calculator" element={<Calculator />} />
        <Route exact path="/dashboard/calculator" element={<Calculator />} />

        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/subscriptions" element={<Subscriptions />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/verify/" element={<Verify />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
