import { Box, HStack, Text, Textarea, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import SectionContainer from "./SectionContainer";
import { BsImage, BsMic, BsSend } from "react-icons/bs";

const ChatFooter = (props) => {
	return (
		<Box margin={0} width={"full"} bg={"#FCFCFC"}>
			<VStack width={"full"} p={"20px"}>
				{" "}
				<HStack
					width={"full"}
					p={"0 12px"}
					border={"1px solid #E6E5E5"}
					justifyContent={"space-between"}
					borderRadius={"4px"}
					alignItems={"center"}
				>
					<BsImage fontSize={"22px"} />
					<BsMic fontSize={"22px"} />
					<Textarea
						minH={"30px"}
						placeholder="Ask me anything"
						width={"90%"}
						resize={"none"}
						border={"none"}
						outline={"none"}
						color={props.aiLoading ? "gray.300": "black"}
						readOnly={props.aiLoading}
						_focusVisible={{ outline: "none" }}
						onChange={props.writeMessage}
						value={props.message}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault()
								props.sendMessage();
								props.setInit(false);
							}
						}}
					/>

					<button
						disabled={props.aiLoading}
						onClick={() => {
							props.sendMessage();
							props.setInit(false);
						}}
					>
						<BsSend
							fontSize={"22px"}
						/>
					</button>
				</HStack>
				<Text textAlign={"center"} fontSize={["xs", "xs", "sm"]}>
					QuidX is an experimental product which may occasionally produce
					inaccurate or misleading results.
				</Text>
			</VStack>
		</Box>
	);
};

export default ChatFooter;
